@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #f0fafa;
    --foreground: 222.2 84% 4.9%;

    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply text-[rgb(var(--foreground-rgb))] bg-[color:var(--background)];
  }

  body::-webkit-scrollbar {
    @apply hidden;
  }

  #header.customFaded {
    @apply opacity-0 pointer-events-none;
  }

  .vertical-scrollbar {
    @apply overflow-y-scroll;
    scrollbar-color: #22bed5 rgb(243, 243, 243);
    overflow: -moz-scrollbars-vertical;
    overflow-x: hidden;
  }

  .vertical-scrollbar::-webkit-scrollbar {
    @apply h-1 absolute right-0 top-0;
    @apply w-1;
  }

  .vertical-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-[#22bed5] rounded-[8px];
  }

  .vertical-scrollbar::-webkit-scrollbar-track {
    @apply bg-transparent rounded-[8px];
  }

  .horizontal-scrollbar {
    @apply overflow-y-scroll;
    scrollbar-color: #22bed5 rgb(243, 243, 243);
    overflow: -moz-scrollbars-horizontal;
    overflow-y: hidden;
  }

  .horizontal-scrollbar::-webkit-scrollbar {
    @apply h-1 absolute left-0 bottom-0;
    @apply w-full;
  }

  .horizontal-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-[#22bed5] rounded-[8px];
  }

  .horizontal-scrollbar::-webkit-scrollbar-track {
    @apply bg-transparent rounded-[8px];
  }

  .header-svg rect:hover {
    @apply fill-[#45d4d5];
  }

  .toggle-down {
    @apply rotate-0;
  }

  .toggle-up {
    @apply rotate-180;
  }

  .scrollbar-hide::-webkit-scrollbar {
    @apply hidden;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .pop-up {
    background: rgb(255, 255, 255);
    background: linear-gradient(8deg,
        rgba(255, 255, 255, 1) 15%,
        rgba(236, 229, 234, 1) 35%,
        rgba(255, 255, 255, 1) 64%);
  }

  .pop-up-sheet {
    background: rgb(255, 255, 255);
    background: linear-gradient(8deg,
        rgba(255, 255, 255, 1) 15%,
        rgba(236, 229, 234, 1) 35%,
        rgba(255, 255, 255, 1) 64%);
  }

  .svg-filter {
    filter: drop-shadow(-1px 0px 1px rgb(0 0 0 / 0.5));
  }

  .scale-up:hover {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  .rotate {
    animation: spinGalaxy 3s ease-in-out forwards;
  }

  @keyframes spinGalaxy {
    0% {
      transform: rotateX(0deg) scale(1);
    }

    100% {
      transform: rotateX(30deg) scale(1.3);
    }
  }

  .animation-stopped {
    animation-play-state: paused;
  }

  .animated-path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: draw 3s ease-in-out infinite;
  }

  @keyframes draw {
    0% {
      stroke-width: 2;
      /* Initial stroke width */
      stroke-dashoffset: 500;
      stroke: black;
      filter: brightness(40%);
    }

    25% {
      stroke: #45d4d5;
      filter: brightness(80%);
    }

    50% {
      stroke-dashoffset: 0;
      stroke-width: 7;
      stroke: #29ecec;
      filter: brightness(100%);
    }

    75% {
      stroke: #45d4d5;
      filter: brightness(80%);
    }

    100% {
      stroke-width: 2;
      /* Final stroke width */
      stroke-dashoffset: 500;
      stroke: black;
      filter: brightness(40%);
    }
  }

  .gradient-border {
    --border-width: 1px;
    position: relative;
    border-radius: var(--border-width) !important;
  }

  .gradient-border::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, #45d4d5, #45d5aa, #45d577, #45d577);
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }

  .fill-back {
    fill: black;
  }

  .flip {
    transform: rotateY(180deg);
  }

  .timeline-item {
    max-width: calc(100vw - 10% - 58px);
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 1.5em top 50%, 0 0;
    background-size: .65em auto, 100%;
  }

  /* For IE (thanks to @SaiManoj) */
  select::-ms-expand {
    display: none;
  }

  .button-box-shadow {
    box-shadow: 0 8px 6px -6px black;
  }

  .chat-form {
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  }

  .default-select {
    appearance: initial !important;
    background-image: initial !important;
    background-position: inherit !important;
    background-size: inherit !important;
    background-repeat: inherit !important;
  }

  .highlighted-text {
    text-shadow: rgb(59, 99, 99) 0px 1px;
  }

  .zoomable-img {
    -webkit-transition: all 0.5s ease-in-out;
    /* Safari and Chrome */
    -moz-transition: all 0.5s ease-in-out;
    /* Firefox */
    -ms-transition: all 0.5s ease-in-out;
    /* IE 9 */
    -o-transition: all 0.5s ease-in-out;
    /* Opera */
    transition: all 0.5s ease-in-out;
    pointer-events: all !important;
  }

  .zoomable-img:hover {
    transform: scale3d(1.2, 1.2, 1.2)
  }
}

@media screen and (min-width: 776px) {
  .pop-up-sheet {
    left: calc(50% - 250px);
  }
}

@media screen and (min-width: 2129px) {
  .gradient-border::after {
    width: calc(100% + var(--border-width) * 3);
    height: calc(100% + var(--border-width) * 3);
  }
}

@media screen and (min-width: 3194px) {
  .gradient-border::after {
    top: calc(-2 * var(--border-width));
  }
}